import request from '@/utils/request'


// 获取2000年之后发射的卫星
export function getSatcats(query) {
  return request({
    url: '/satcats',
    method: 'get',
    params: query
  })
}

// 获取二行数据
export function satelliteTowLines(query) {
  return request({
    url: '/satellite_tow_lines',
    method: 'get',
    params: query
  })
}
// 获取分页数据
export function satellitePageTowLines(query) {
  return request({
    url: '/satellite_page_tow_lines',
    method: 'get',
    params: query
  })
}
// 详情
export function satcatDetail(query) {
  return request({
    url: '/satcat_detail',
    method: 'get',
    params: query
  })
}

// 搜索
export function SearchSatcat(query) {
  return request({
    url: '/search_satcat',
    method: 'get',
    params: query
  })
}
// 搜索结果查询
export function searchSatcatPage(query) {
  return request({
    url: '/satcat_page',
    method: 'post',
    data: query
  })
}
// 卫星分页列表
export function satcatPage(query) {
  return request({
    url: '/satcat_page',
    method: 'post',
    data: query
  })
}
// 分型列表 （不分页）
export function satcatList(query) {
  return request({
    url: '/satcat_list',
    method: 'post',
    data: query
  })
}
// 国家列表
export function CountryList(query) {
  return request({
    url: '/country_list',
    method: 'get',
    params: query
  })
}
// 分组列表
export function GroupList(query) {
  return request({
    url: '/satcat_group_list',
    method: 'get',
    params: query
  })
}

// 统计数据
export function SatcatCountData(query) {
  return request({
    url: '/satcat_count_data',
    method: 'get',
    params: query
  })
}

// 获取事件
export function getSatelliteChange(query) {
  return request({
    url: '/get_satcat_change',
    method: 'get',
    params: query
  })
}

// 获取预警
export function getSatelliteConjunctions(query) {
  return request({
    url: '/get_conjunctions_list',
    method: 'get',
    params: query
  })
}

// 获取发射
export function getLaunches(query) {
  return request({
    url: '/get_launches_list',
    method: 'get',
    params: query
  })
}
// 获取发射详情
export function getLaunchesDetail(query) {
  return request({
    url: '/get_launches_detail',
    method: 'get',
    params: query
  })
}

// 快照保存
export function shotcutActDo(data) {
  return request({
    url: '/shotcut_act_do',   
    method: 'post',
    data: data
  })
}

// 获取快照列表
export function getShotcutList(query) {
  return request({
    url: '/get_shotcut_list',
    method: 'get',
    params: query
  })
}
// 获取快照数据
export function getShotcutData(query) {
  return request({
    url: '/get_shotcut_data',
    method: 'get',
    params: query
  })
}














