<!--  -->
<template>
  <div class="home">
    <transition name="el-fade-in-linear">
      <div class="login" v-show="loginShow" @click="loginShow = false">
        <div class="login-box" @click.stop>
          <div class="login-form-box">
            <dv-border-box-11 :key="loginkey" ref="borderBoxLogin" :color="['#48A2B3','#48A2B3']" class="border-box"
              title="登录">
              <el-form ref="loginForm" :model="form" :rules="loginRules" class="login-form" :show-message="false">
                <el-form-item prop="username">
                  <el-input style="width: 50%;" placeholder="账号或手机号" suffix-icon="el-icon-user" v-model="form.username">
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input style="width: 50%;margin-top: 20px;" type="password" suffix-icon="el-icon-lock"
                    v-model="form.password"></el-input>
                </el-form-item>
                <el-form-item style="width:100%;display: flex;justify-content: space-around;">
                  <el-button :plain="true" @click="showRegister" round style="margin-top: 20px;">注册账号</el-button>
                  <el-button type="success" :loading="loading" @click="logoDo" round
                    style="margin-top: 20px;background: #48A2B3;">登录并前往</el-button>
                </el-form-item>
              </el-form>
            </dv-border-box-11>
          </div>

        </div>
      </div>

    </transition>


    <transition name="el-fade-in-linear">
      <div class="register" v-show="registerShow" @click="registerShow = false">
        <div class="register-box" @click.stop>
          <div class="register-form-box">
            <dv-border-box-11 :key="registerkey" ref="borderBox" :color="['#48A2B3','#48A2B3']" class="border-box"
              title="注册账号">
              <el-form ref="registerForm" :model="registerform" :rules="registerRules" class="register-form"
                :show-message="true" :inline-message="false">

                <el-form-item prop="username">
                  <el-input style="width: 50%;" v-model="registerform.username" placeholder="账号">
                  </el-input>
                </el-form-item>

                <el-form-item prop="codeValue">
                  <el-row style="width: 50%;margin-left: 25%;">
                    <el-col :span="16">
                      <el-input v-model="registerform.codeValue" type="text" placeholder="验证码"></el-input>
                    </el-col>
                    <el-col :span="7" :offset="1" style="margin-left: 5px;margin-top: 2px; text-align: center;">
                      <img :src="codeSrc" style="background-color:#fff;" @click="reloadcaptcha()" title="点击图片重新获取验证码"
                        id="captchapng" width="70" height="30">
                    </el-col>

                  </el-row>
                </el-form-item>
                <!-- <el-form-item prop="captcha">
                  <el-row style="width: 50%;margin-left: 25%;">
                    <el-col :span="16">
                      <el-input v-model="registerform.captcha" placeholder="验证码"></el-input>
                    </el-col>
                    <el-col :span="7" :offset="1">
                      <el-button type="primary" :disabled="canClick" @click="sendMsg" size="small"
                        v-loading="sendLoading">{{content}}</el-button>
                    </el-col>
                  </el-row>
                </el-form-item> -->
        
                <!-- <el-form-item prop="code">
                  <el-input style="width: 50%;" type="number" v-model="registerform.code" placeholder="短信验证码">
                  </el-input>
                </el-form-item> -->
                
                <el-form-item prop="password">
                  <el-input style="width: 50%;" v-model="registerform.password" type="password" auto-complete="aaa"
                    placeholder="密码">
                  </el-input>
                </el-form-item>
                <el-form-item prop="repassword">
                  <el-input style="width: 50%;" v-model="registerform.repassword" type="password" auto-complete="aaa"
                    placeholder="重复密码" @keyup.enter.native="handleLogin">
                  </el-input>
                </el-form-item>
                <el-form-item prop="truename">
                  <el-input style="width: 50%;" v-model="registerform.truename" type="text" auto-complete="aaa"
                    placeholder="姓名">
                  </el-input>
                </el-form-item>
                <el-form-item prop="idcode">
                  <el-input style="width: 50%;" v-model="registerform.idcode" type="text" auto-complete="aaa"
                    placeholder="中国大陆身份证号">
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="registerform.access"></el-checkbox><span
                    style="color:#c8c8c8;margin-left: 10px;cursor: pointer;"
                    @click="dialogVisible = true">我已阅读并同意服务条款</span>
                </el-form-item>
                <el-form-item>
                  <el-row>
                    <el-col :span="24" style="text-align: center;">
                      <el-button :style="registerform.access?'background-color:#48A2B3;':'background-color:#c8c8c8;'"
                        :disabled="!registerform.access" :loading="registerloading" size="medium" type="primary"
                        @click.native.prevent="handleRegister">
                        <span v-if="!registerloading">注 册</span>
                        <span v-else>保 存 中...</span>
                      </el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>
            </dv-border-box-11>
          </div>
        </div>
      </div>
    </transition>

    <el-dialog title="用户协议" :visible.sync="dialogVisible" width="80%">
      <span>请仔细阅读以下用户协议条款！本网站仅允许获得批准的用户访问美国政府空间态势感知信息。要获得访问权限，所有用户都必须遵守以下条款和条件：</br>

        用户同意，未经事先明确批准，不会将从本网站或其他美国政府来源收到的任何数据或技术信息（包括数据分析）传输给任何其他实体。参见 10 USC 2274(c)(2)。</br>

        用户同意支付可能收取的金额。目前美国政府的政策是不对网站访问收费。如果此政策发生变化，该网站将提供通知。参见 10 USC 2274(c)(1)。</br>

        用户同意使美国政府、其任何机构和机构以及代表美国行事的任何个人、公司、公司和其他人免受损害。对于因提供或接收空间态势感知服务或信息（无论是否根据 10 USC 2274
        提供）或任何相关作为或不作为而引起的任何诉讼因由，此类人应免受任何诉讼。参见 10 USC 2274 (g)。</br>

        用户同意他或她将仅使用他或她自己的用户名和密码进入本网站。用户同意不将他或她的用户名或密码分享、分配或转移给他人。每个个人用户或实体都需要获得一个单独的帐户。参见 10 USC 2274(c)(3)。</br>

        用户同意在注册时和帐户使用期间提供有效且有效的电子邮件地址。这确保了与用户的有效沟通和向用户提供服务。作为注册过程的一部分，用户将被要求提供并验证他/她的电子邮件地址。</br>

        用户同意这些条款和条件应受美国联邦法律管辖并根据其解释。</br>
        </br>
        批准的访问权限必须定期更新。美国政府可以出于任何原因单方面终止本协议。

        上述商定的条款和条件不会以任何方式改变与美国政府就空间态势感知服务和信息达成的任何其他书面协议。美国政府保留随时更改或修改这些条款和条件的权利，恕不另行通知。</br>

        </br>
        警告！
        </br>
        两行元素 (TLE) 集是报告的每个空间物体在给定时间点的平均开普勒轨道元素。TLE 是使用简化的一般扰动理论生成的，并且在很长一段时间内都相当准确。不应将向公众提供的 TLE 用于联合评估预测。卫星运营商被指示通过
        18SPCS.doo.customerservice@us.af.mil 联系第 18 太空防御中队，以获取适当的数据和分析以支持卫星运行。
        </br>
        由于日常维护和更新，本网站可能会在短时间内无法访问。美国政府保留限制任何用户的访问持续时间和数据量的权利。
        </br>
        美国政府不保证本网站的准确性或完整性，也不保证网站不会中断、没有错误、缺陷会得到纠正、网站或服务器没有病毒或其他技术问题。
        </br>
        当前截至 2019 年 8 月 1 日。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="primary" style="background-color:#c8c8c8;">关 闭</el-button>
      </span>
    </el-dialog>

    <div class="logo">
      <el-image style="width: 150px; height: 75px" :src="logourl" :fit="'fill'"></el-image>
    </div>

    <!-- <div class="title">
      <div class="title-text">太空罗盘</div>
    </div> -->

    <transition name="el-fade-in">
      <div v-show="show">
        <div class="transition-box">
          <div class="c011333">
            <div class="c011343 c01137 c01299 c01138" style="opacity: 1;">
              <div class="c01140 c01300 revealText--wrap" style="opacity: 1;">
                <div class="line">
                  <div
                    style="color: #909399; font-size: 28px; font-family: Adobe Heiti Std R;display: block; position: relative; transform-origin: 50% 0%; transform: translate(0px, 0%);">
                    航天器: {{countData.playload}} &nbsp;&nbsp;空间碎片: {{countData.debris}}</div>
                </div>
                <div class="line">
                  <div
                    style="display: block; text-align: start; position: relative; transform-origin: 50% 0%; transform: translate(0px, 0%);">
                    <span class="space"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="el-fade-in">
      <div v-show="show">
        <div class="transition-box">
          <div class="c011334">
            <div style="opacity: 1;">
              <div class="line">
                技术支持: 北京未来宇航
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <el-dropdown class="menu-box">
      <div class="enter-wrapper" @click="goMap">进入系统</div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>查看</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div id="hcontainer">

    </div>
  </div>
</template>

<script>
  import * as Three from 'three'
  // 导入轨道控制器
  import {
    OrbitControls
  } from 'three/examples/jsm/controls/OrbitControls'
  import Stats from 'three/examples/jsm/libs/stats.module'
  import {
    GLTFLoader
  } from 'three/examples/jsm/loaders/GLTFLoader';
  import {
    TWEEN
  } from "three/examples/jsm/libs/tween.module.min.js";
  let scene = null,
    camera = null,
    renderer = null,
    earthGroup = null,
    controls = null,
    satellites = [],
    satellite = null,
    stats = null,
    tween = null,
    uniforms = null,
    rotatingApertureMesh = null,
    rotatingPointMesh = null,
    particleArr = [],
    WaveMeshArr = [], //所有波动光圈集合
    uniforms2 = {
      u_time: {
        value: 0.0
      }
    }
  import {
    loginDo,
    registerDo,
    getCode,
    registerSendSms
  } from "@/api/login";
  import {
    SatcatCountData
  } from "@/api/map";
  export default {
    name: "home",
    data() {
      var validQC = (rule, value, callback) => {
        if (value) {
          var reg = /^1[3456789]\d{9}$/;
          if (reg.test(value)) {
            callback();
            return true;
          } else {
            callback(new Error('手机格式不正确'));
          }
        }
      };
      var repasswordValidator = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.registerform.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      //身份证校验
      var isCnNewID = (rule, value, callback) => {
        var arrExp = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; //加权因子
        var arrValid = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2]; //校验码
        if (/^\d{17}\d|x$/i.test(value)) {
          var sum = 0,
            idx;
          for (var i = 0; i < value.length - 1; i++) {
            // 对前17位数字与权值乘积求和
            sum += parseInt(value.substr(i, 1), 10) * arrExp[i];
          }
          // 计算模（固定算法）
          idx = sum % 11;
          // 检验第18为是否与校验码相等
          if (arrValid[idx] == value.substr(17, 1).toUpperCase()) {
            callback()
          } else {
            callback("身份证格式有误")
          }
        } else {
          callback("身份证格式有误")
        }
      };
      return {
        backSrc: require(`/src/assets/menu/homeBack.png`),
        logourl: require(`/static/image/logo3.png`),
        menuurl: require(`/src/assets/menu/menu.png`),
        show: false,
        loginShow: false,
        loginkey: 11,
        registerkey: 1000,
        form: {},
        loginRules: {
          username: [{
            required: true,
            trigger: "blur",
            message: "用户名不能为空"
          }],
          password: [{
            required: true,
            trigger: "blur",
            message: "密码不能为空"
          }],
        },
        loading: false,
        dialogVisible: false,
        registerShow: false,
        registerform: {
          username: undefined,
          truename: undefined,
          password: undefined,
          repassword: undefined,
          access: false,
          code: undefined,
          idcode: undefined,
          codeId: undefined,
          codeValue: undefined,
        },
        registerRules: {
          username: [{
              required: true,
              message: '请输入账号',
              trigger: 'blur'
            },
            // {
            //   validator: validQC,
            //   trigger: 'blur'
            // },
          ],
          codeValue: [{
            required: true,
            message: '请输入图形验证码',
            trigger: "blur"
          }],
          code: [{
            required: true,
            message: '请输入短信验证码',
            trigger: "blur"
          }],
          password: [{
            required: true,
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: "blur"
          }],
          repassword: [{
              required: true,
              min: 6,
              max: 20,
              message: '俩次输入不一致',
              trigger: "blur"
            },
            {
              validator: repasswordValidator,
              trigger: 'blur'
            },
          ],
          idcode: [{
              required: true,
              message: '请输入中国大陆验证码',
              trigger: "change"
            },
            {
              validator: isCnNewID,
              trigger: 'change'
            },
          ],
          truename: [{
            required: true,
            min: 2,
            max: 20,
            message: '请输入姓名',
            trigger: "blur"
          }],
        },
        registerloading: false,
        countData: {},
        content: '发送短信',
        canClick: false,
        sendLoading: false,
        codeSrc: '',
        totalTime: 60
      };
    },
    methods: {
      /** 查询统计数据 */
      getCountData() {
        SatcatCountData({}).then(
          response => {
            this.countData = {
              playload: response.data.playload,
              debris: response.data.debris
            }
            // console.log(this.countData)
          }
        );
      },
      init() {
        let hcontainer = document.getElementById('hcontainer');

        // 添加相机
        camera = new Three.PerspectiveCamera(10, hcontainer.clientWidth / hcontainer.clientHeight, 1, 1000);
        // 相机的位置
        camera.position.set(200, 600, 200);
        // 场景
        scene = new Three.Scene()
        this.addBack()

        var fluctmaterial = new Three.MeshBasicMaterial({
          color: 0x00ffff, //设置光圈颜色
          map: new Three.TextureLoader().load(require(`/static/image/10.jpg`)),
          transparent: true, //使用背景透明的png贴图，注意开启透明计算
          // side: Three.DoubleSide, //双面可见
        });

        // stats = new Stats();
        // stats.domElement.style.position = 'fiexd'; //绝对坐标  
        // stats.domElement.style.right = '0px';// (0,0)px,左上角  
        // stats.domElement.style.top = '0px';
        // stats.domElement.style.left = '';
        // stats.domElement.style.justifyContent = 'end';
        // document.getElementById('hcontainer').appendChild(stats.domElement);



        earthGroup = new Three.Group(); //地球的组
        let earthGeometry = new Three.SphereGeometry(20, 100, 100); //地球几何体
        //地球材质
        let earthMaterial = new Three.MeshPhysicalMaterial({
          map: new Three.TextureLoader().load(require(`/static/image/10.jpg`)),
          normalScale: new Three.Vector2(10, 10), //凹凸深度
          // normalMap: new Three.TextureLoader().load(require(`/static/image/bump.jpeg`)), //法线贴图
        });
        let earthMesh = new Three.Mesh(earthGeometry, earthMaterial); //地球网格
        earthGroup.add(earthMesh); //将地球网格添加到地球组中

        //地球云层几何体
        let earthCloudsGeometry = new Three.SphereGeometry(
          20 + 2,
          100,
          100
        );
        //地球云层材质
        let earthCloudsMaterial = new Three.MeshLambertMaterial({
          color: new Three.Color(0xffffff),
          transparent: true,
          opacity: 0.4,
          map: new Three.TextureLoader().load(require(`/static/image/cloud.png`)),
        });
        //地球云层网格
        let earthCloudsMesh = new Three.Mesh(
          earthCloudsGeometry,
          earthCloudsMaterial
        );
        earthGroup.add(earthCloudsMesh);

        //球体位置
        earthGroup.position.set(
          0,
          0,
          0
        );
        scene.add(earthGroup);

        let ambient = new Three.AmbientLight(new Three.Color(0xffffff), 0.2); //环境光
        scene.add(ambient);
        let pointLight = new Three.PointLight(new Three.Color(0xffffff), 1, 1, 0); //点光源
        pointLight.visible = true;
        pointLight.position.set(-1000, 800, 300); //点光源在原点充当太阳
        scene.add(pointLight); //点光源添加到场景中

        // 初始化渲染器
        renderer = new Three.WebGLRenderer({
          antialias: true,
          alpha: true
        });
        renderer.outputEncoding = Three.sRGBEncoding;
        // 渲染器的大小
        renderer.setSize(hcontainer.clientWidth, hcontainer.clientHeight);
        // 将渲染器添加到页面
        hcontainer.appendChild(renderer.domElement);

        // this.initParticle();

        this.initFloor();

        // 创建轨道控制器 相机围绕模型看到的角度
        controls = new OrbitControls(camera, renderer.domElement)
        // 设置轨道自然
        controls.enableDamping = true;
        controls.enableZoom = true;
        controls.autoRotate = false;
        controls.autoRotateSpeed = 2;
        controls.enablePan = true;
        controls.dampingFactor = 0.5;
        controls.maxPolarAngle = (Math.PI / 4) * 3; //控制器垂直方向最大旋转角度（理解为逆时针旋转角度）
        controls.minPolarAngle = (Math.PI / 4) * 1; //控制器垂直方向最大小旋转角度（理解为顺时针旋转角度）
        controls.minDistance = 100; //限制视线最近距离
        controls.maxDistance = 2600; //限制视线多远距离


        let satelliteimg = require('/static/image/satellite.png');

        satellite = new Three.Sprite(new Three.SpriteMaterial({
          map: new Three.TextureLoader().load(satelliteimg),
          blending: Three.AdditiveBlending
        }));
        //添加卫星

        for (var i = 0; i < 1000; i++) {
          let base = Math.random() * 10;
          satellites.push(this.initSatellite(1, 28, {
            x: -Math.PI * base,
            y: Math.PI * base * 0.25 + 60,
            z: 0
          }, Math.random() * 0.1 * 0.1, satellite.clone(), scene))
        }

        // let gltfd = null;
        // let that = this
        // let loader = new GLTFLoader()
        // loader.load( 'model/gltf/Satellite1/scene.gltf', function ( gltf ) {
        //   // console.log(gltf)
        //   gltf.scene.scale.set(0.01,0.01,0.01);
        //   gltf.scene.position.set(25, 25, 25);
        //   gltf.scene.traverse( function ( child ) {
        //     if ( child.isMesh ) {
        //         child.frustumCulled = true;
        //         //模型阴影
        //         child.castShadow = false;
        //         //模型自发光
        //         child.material.emissive =  child.material.color;
        //         child.material.emissiveMap = child.material.map ;
        //     }
        //   })
        //   gltfd = gltf.scene
        //   for (var i = 0; i < 40; i++) {
        //     let base = Math.random() * 10;
        //     satellites.push(that.initSatellite(0.4, 28, {x: -Math.PI * base, y: Math.PI * base * 0.25 + 60, z: 0}, Math.random()*0.1*0.1, gltfd.clone(), scene))
        //   }
        //   // scene.add( gltf.scene );
        // })




        this.move()

      },

      /**
       * 相机移动方法
       */
      move() {
        // 设定相机初始位置
        var coords = {
          x: 200,
          y: 1800,
          z: 200
        };
        tween = new TWEEN.Tween(coords)
          .to({
            x: 200,
            y: 595,
            z: 200
          }, 3000) // 指定目标位置和耗时.
          .easing(TWEEN.Easing.Quadratic.Out) // 指定动画效果曲线.
          .onUpdate(() => {
            // 渲染时每一帧执行：设定相机位置
            camera.position.set(coords.x, coords.y, coords.z);
          })
          .onComplete(() => {
            // 动画结束后执行
            controls.maxDistance = 600;
            let that = this;
            setTimeout(function () {
              that.show = true
            }, 500)
          })
          .start() // 即刻开启动画
      },

      initFloor() {
        const geometry = new Three.PlaneGeometry(400, 400);

        //底部颜色
        let texture = new Three.TextureLoader().load(require('/static/image/地板背景.png'))

        let rotatingApertureTexture = new Three.TextureLoader().load(require('/static/image/rotatingAperture.png'))
        let rotatingApertureerial = new Three.MeshBasicMaterial({
          map: rotatingApertureTexture,
          transparent: true,
          opacity: 1,
          depthTest: true,
          depthWrite: false,
        });
        let rotatingApertureGeometry = new Three.PlaneGeometry(50, 50);
        rotatingApertureMesh = new Three.Mesh(rotatingApertureGeometry, rotatingApertureerial);
        rotatingApertureMesh.rotateX(-Math.PI / 2)
        rotatingApertureMesh.position.y = 0.02
        rotatingApertureMesh.scale.set(1.8, 1.8, 1.8);
        scene.add(rotatingApertureMesh);

        let rotatingPointTexture = new Three.TextureLoader().load(require('/static/image/rotating-point2.png'))
        let material2 = new Three.MeshBasicMaterial({
          map: rotatingPointTexture,
          transparent: true,
          opacity: 1,
          depthTest: true,
          depthWrite: false,
        });

        rotatingPointMesh = new Three.Mesh(rotatingApertureGeometry, material2);
        rotatingPointMesh.rotateX(-Math.PI / 2)
        rotatingPointMesh.position.y = 0.04
        rotatingPointMesh.scale.set(1.5, 1.5, 1.5);
        scene.add(rotatingPointMesh);

        let circlePoint = new Three.TextureLoader().load(require('/static/image/circle-point.png'))
        let material3 = new Three.MeshPhongMaterial({
          color: 0x00ffff,
          map: circlePoint,
          transparent: true,
          opacity: 1,
          depthWrite: false,
          // depthTest: false,
        });
        let plane3 = new Three.PlaneGeometry(60, 60);
        let mesh3 = new Three.Mesh(plane3, material3);
        mesh3.rotateX(-Math.PI / 2)
        mesh3.position.y = 0.06
        mesh3.scale.set(1.5, 1.5, 1.5);
        scene.add(mesh3);
      },

      initSatellite(satelliteSize, satelliteRadius, rotation, speed, satellite, scene) {
        // var track = new Three.Mesh(new Three.RingGeometry(satelliteRadius, satelliteRadius + 0.1, 50, 1), new Three.MeshBasicMaterial({color:0xffffff,wireframe:false,side:Three.DoubleSide}));
        var centerMesh = new Three.Mesh(new Three.SphereGeometry(1, 1, 1), new Three.MeshLambertMaterial()); //材质设定

        satellite.scale.x = satellite.scale.y = satellite.scale.z = satelliteSize;
        satellite.position.set(satelliteRadius, 0, 0);

        var pivotPoint = new Three.Object3D();
        pivotPoint.add(satellite);
        // pivotPoint.add(track);
        centerMesh.add(pivotPoint);
        centerMesh.rotation.set(rotation.x, rotation.y, rotation.z);
        scene.add(centerMesh);
        return {
          satellite: centerMesh,
          speed: speed
        };
      },

      /**  
       * 实现发光星星  
       * @param color 颜色的r,g和b值,比如：“123,123,123”;  
       * @returns {Element} 返回canvas对象  
       */
      generateSprite(color) {
        var canvas = document.createElement('canvas');
        canvas.width = 16;
        canvas.height = 16;
        var context = canvas.getContext('2d');
        var gradient = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas
          .height / 2, canvas.width / 2);
        gradient.addColorStop(0, 'rgba(' + color + ',1)');
        gradient.addColorStop(0.2, 'rgba(' + color + ',1)');
        gradient.addColorStop(0.4, 'rgba(' + color + ',.6)');
        gradient.addColorStop(1, 'rgba(0,0,0,0)');
        context.fillStyle = gradient;
        context.fillRect(0, 0, canvas.width, canvas.height);
        return canvas;
      },
      addBack() {
        const positions = [];
        const colors = [];
        const geometry = new Three.BufferGeometry();
        for (var i = 0; i < 10000; i++) {
          var vertex = new Three.Vector3();
          vertex.x = Math.random() * 2 - 1;
          vertex.y = Math.random() * 2 - 1;
          vertex.z = Math.random() * 2 - 1;
          positions.push(vertex.x, vertex.y, vertex.z);
          var color = new Three.Color();
          color.setHSL(Math.random() * 0.2 + 0.5, 0.55, Math.random() * 0.25 + 0.55);
          colors.push(color.r, color.g, color.b);
        }
        geometry.setAttribute("position", new Three.Float32BufferAttribute(positions, 3));
        geometry.setAttribute("color", new Three.Float32BufferAttribute(colors, 3));

        var textureLoader = new Three.TextureLoader();
        var texture = textureLoader.load(require('/static/image/star.png')); //加载纹理贴图

        var backMaterial = new Three.PointsMaterial({
          map: texture,
          size: 1,
          transparent: true,
          opacity: 1,
          vertexColors: true, //true：且该几何体的colors属性有值，则该粒子会舍弃第一个属性--color，而应用该几何体的colors属性的颜色
          blending: Three.AdditiveBlending,
          sizeAttenuation: true,
        });
        let back = new Three.Points(geometry, backMaterial);
        back.scale.set(300, 300, 300);
        scene.add(back);
      },

      animate() {
        earthGroup.rotation.y += 0.001

        for (var i = 0; i < satellites.length; i++) {
          satellites[i].satellite.rotation.z -= satellites[i].speed;
        }

        if (rotatingApertureMesh) {
          rotatingApertureMesh.rotation.z += 0.0005;
        }
        if (rotatingPointMesh) {
          rotatingPointMesh.rotation.z -= 0.0005;
        }


        if (tween) {
          TWEEN.update();
        }
        if (controls) {
          controls.update();
        }
        // 浏览器自动渲染下一帧
        requestAnimationFrame(this.animate);
        // stats.update();
        // 渲染到页面
        renderer.render(scene, camera);
      },
      goMap() {
        var that = this
        let token = that.$store.getters.getToken
        if (token == "" || token == 'undefined') {
          that.loginShow = true
          that.$nextTick(() => {
            that.loginkey++
          })
        } else {
          that.$emit('goStep');
        }

      },
      logoDo() {
        debugger
        let that = this
        that.$refs.loginForm.validate(valid => {
          if (valid) {
            that.loading = true;
            loginDo(that.form).then(res => {
              if (res.code == 0) {
                that.msgSuccess("登录成功！");
                that.$store.commit("setToken", res.data.token)
                that.$store.commit("setUserInfo", res.data.username)
                setTimeout(function () {
                  that.$emit('goStep');
                }, 300);
              } else {
                that.msgError(res.msg)
                that.loading = false;
              }
            });
          }
        });

      },
      showRegister() {
        this.loginShow = false
        this.registerShow = true

        this.reloadcaptcha()

        setTimeout(() => {
          this.$refs.borderBox.initWH()
        }, 100)
      },
      reloadcaptcha() {
        getCode().then(res => {
          if (res.code == 0) {
            this.codeSrc = 'data:image/gif;base64,' + res.data.img
            this.registerform.codeId = res.data.codeId
          } else {
            this.msgError(res.msg)
          }
        });
      },
      handleRegister() {
        let that = this
        that.$refs.registerForm.validate(valid => {
          if (valid) {
            that.registerloading = true;
            registerDo(that.registerform).then(res => {
              if (res.code == 0) {
                that.msgSuccess("用户注册成功");
                setTimeout(function () {
                  that.loginShow = true
                  that.registerShow = false
                  setTimeout(() => {
                    that.$refs.borderBoxLogin.initWH()
                    that.resetRegisterForm()
                  }, 100)
                }, 300);
              } else {
                that.msgError(res.msg)
                that.registerloading = false;
              }
            });
          }
        });
      },

      resetRegisterForm() {
        this.registerform = {
          username: undefined,
          truename: undefined,
          password: undefined,
          repassword: undefined,
          access: false,
          code: undefined,
          captcha: undefined,
          captchaid: 0,
          idcode: undefined
        }
        this.registerloading = false
      },
      sendMsg() {
        let that = this
        let phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/
        if (!that.registerform.username) {
          that.$message.error('请输入电话号码！');
          return
        }
        if (!phoneReg.test(that.registerform.username)) {
          that.$message.error('电话号码格式不正确');
          return
        }
        if (!that.registerform.captcha) {
          that.$message.error('请输入图形验证码');
          return
        }
        if (that.canClick) return
        that.sendLoading = true
        let da = {
          'mobile': that.registerform.username,
          'captcha': that.registerform.captcha
        }
        registerSendSms(da).then(res => {
          that.sendLoading = false
          if (res.code == 1) {
            that.$message.success('发送成功');
            that.canClick = true
            that.content = that.totalTime + 's后重新发送'
            let clock = window.setInterval(() => {
              that.totalTime--
              that.content = that.totalTime + 's后重新发送'
              if (that.totalTime < 0) {
                window.clearInterval(clock)
                that.content = '重新发送短信'
                that.totalTime = 10
                that.canClick = false
              }
            }, 1000)
          } else if (res.code == 2) {
            that.$message.error('手机号码已被注册');
          } else {
            that.$message.error('发送失败' + res.msg);
          }
        });
      }
    },
    mounted() {
      this.init()
      this.animate()
      this.getCountData()
    },
    created() {
      // 将当前窗口高度和宽度保存在vuex中
      this.$store.commit("setWindowHeight", window.innerHeight)
      this.$store.commit("setWindowWidth", window.innerWidth)
    }

  }

</script>
<style scoped>

  .enter-wrapper {
    font-size: 18px;
    font-weight: 600;
    color: #909399;
  }
  #hcontainer {
    width: 100vw;
    height: 100vh;
    /**background: url(~@/assets/menu/homeBack.png) 0px 0px no-repeat;*/
    background-color: rgba(0, 0, 0, 1);
    background-size: 100vw 100vh;
    position: absolute;
    overflow: hidden;
  }

  .home .title {
    width: 100%;
    height: 50px;
    z-index: 9999;
    text-align: center;
    position: absolute;
    color: #fff;
    margin-top: 10px;
  }

  .home .title-text {
    overflow-wrap: break-word;
    color: rgba(117, 224, 255, 1);
    font-size: 25px;
    font-family: SourceHanSansCN-Regular;
    white-space: nowrap;
    line-height: 50px;
  }

  .home .c011333 {
    width: 100%;
    top: 50px;
    text-align: center;
    z-index: 1;
    position: absolute;
    color: #fff;
  }

  .home .c011334 {
    width: 200px;
    bottom: 50px;
    right: 30px;
    text-align: center;
    z-index: 10000;
    position: absolute;
    color: #909399;
  
  }

  .home .c011343 {
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 16px;
    padding-left: 9vw;
    padding-right: 9vw;
    z-index: 9999;
  }

  .home .logo {
    position: fixed;
    left: 50px;
    top: 50px;
    z-index: 9999;
    color: #fff;
    display: flex;

  }

  .home .menu-box {
    right: 70px;
    top: 40px;
    z-index: 9999;
    position: fixed;
    color: #fff;
    /*animation:  view_icon 1s linear infinite;*/
    cursor: pointer;
  }

  .home .menu-box:hover {
    animation-play-state: paused;
  }

  @keyframes view_icon {
    0% {
      opacity: 0.8;
      transform: translate(0, 0);
    }

    50% {
      opacity: 1;
      transform: translate(0, 20px);
    }

    100% {
      opacity: 0.8;
      transform: translate(0, 0);
    }
  }

  .home .login-box {
    width: 100%;
    text-align: center;
    bottom: 40%;
    z-index: 1000;
    position: absolute;
  }

  .home .login-form-box {
    color: #fff;
    width: 500px;
    height: 200px;
    margin: 20px auto;
  }

  .border-box {
    text-align: center;
    align-items: center;
  }

  .home .login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }

  .home .register-box>>>.el-form-item__error {
    margin-left: 25% !important;
  }

  .home .register-box {
    width: 100%;
    text-align: center;
    bottom: 40%;
    z-index: 1000;
    top: 100px;
    position: absolute;
  }

  .home .register-form-box {
    color: #fff;
    width: 500px;
    height: 500px;
    top: 20px;
    margin: 20px auto;
  }


  .home .register {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }


  .border-box {
    padding-top: 100px;
  }

  .home .el-button--success.is-active,
  .el-button--success:active {
    background: rgb(138, 170, 251);
    border-color: rgb(138, 170, 251);
    color: #FFF;
  }

  /*.home .el-button--success:focus, .el-button--success:hover {
    background: #91dbdb;
    border-color: #91dbdb;
    color: #FFF;
}*/
  .home .el-button--success {
    color: #FFF;
    background-color: rgb(138, 170, 251);
    border-color: rgb(138, 170, 251);
  }

</style>
